import React from "react";
import PropTypes from "prop-types";

const Footer = props => (
  <footer id="footer" style={props.timeout ? { display: "none" } : {}}>
    <div className="copyright">
      &copy; Dimension. Design: <a href="https://html5up.net">HTML5 UP</a>.
      Built with: <a href="https://www.gatsbyjs.org/">Gatsby.js</a>
    </div>
  </footer>
);

Footer.propTypes = {
  timeout: PropTypes.bool
};

export default Footer;

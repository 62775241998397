import React from 'react'
import PropTypes from 'prop-types'
import './Header.css'
import mxbcs1 from '../sounds/mxbcs.mp3'
import mxbcs3 from '../sounds/mxbcs.wav'

// need to put the vars inside the function to build successfullly
function playAudio() {
    var x = new Audio(mxbcs1);
    var z = new Audio(mxbcs3);
    x.play();
    z.play();
} 

const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        <div className="fullpage-wrapper" onClick={() => playAudio()}>
            <div className="reactor-container">
                <div className="reactor-container-inner circle abs-center"></div>
                <div className="tunnel circle abs-center"></div>
                <div className="core-wrapper circle abs-center"></div>
                <div className="core-outer circle abs-center"></div>
                <div className="core-inner circle abs-center"></div>
                <div className="coil-container">
                <div className="coil coil-1"></div>
                <div className="coil coil-2"></div>
                <div className="coil coil-3"></div>
                <div className="coil coil-4"></div>
                <div className="coil coil-5"></div>
                <div className="coil coil-6"></div>
                <div className="coil coil-7"></div>
                <div className="coil coil-8"></div>
                </div>
            </div>
        </div>
        <div className="content">
            <div className="inner">
                <h1>Nyob Zoo World</h1>
                <p>My name is Addy and I <span role="img">🤣</span> and <span role="img">😀</span></p>
            </div>
        </div>
        <nav>
            <ul>
                <li><a onClick={() => {props.onOpenArticle('intro')}}>Introduction</a></li>
                <li><a onClick={() => {props.onOpenArticle('work')}}>About Me{'\u00A0'}</a></li>
                <li><a onClick={() => {props.onOpenArticle('about')}}>More About Me</a></li>
                <li><a onClick={() => {props.onOpenArticle('contact')}}>Leave Me A Message</a></li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header

import React from "react";
import PropTypes from "prop-types";

import pic01 from "../images/pic01.png";
import pic02 from "../images/pic02.png";
import pic03 from "../images/pic03.jpg";
// import pic05 from '../images/pic05.png'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle();
        }}
      ></div>
    );

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: "flex" } : { display: "none" }}
      >
        <article
          id="intro"
          className={`${this.props.article === "intro" ? "active" : ""} ${
            this.props.articleTimeout ? "timeout" : ""
          }`}
          style={{ display: "none" }}
        >
          <h2 className="major">Introduction</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
            My name is Adalynn Yang. I will be turning a year old. I have an
            older brother and lots of space to make trouble now.
          </p>

          <p>
            Thank you for coming to my first birthday, and I hope you like our
            house! Come visit us anytime <span role="img">😉</span>
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === "work" ? "active" : ""} ${
            this.props.articleTimeout ? "timeout" : ""
          }`}
          style={{ display: "none" }}
        >
          <h2 className="major">About Me </h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <p>
            I love to talk and am starting to get a hang of this "walking"
            thing. I love playing with my brother, looking outside, and being a
            boss. I also love my mommy and daddy. My favorite items are my
            blanket, pacifier, and balloons.
          </p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === "about" ? "active" : ""} ${
            this.props.articleTimeout ? "timeout" : ""
          }`}
          style={{ display: "none" }}
        >
          <h2 className="major">More About Me</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <p>
            I can clap, crawl, and screech. My favorite foods are noodles, tofu,
            and bread. I have 4 teeth.
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === "contact" ? "active" : ""} ${
            this.props.articleTimeout ? "timeout" : ""
          }`}
          style={{ display: "none" }}
        >
          <h3 className="major">
            Please leave me a message
            <br />
            for future me to read
          </h3>
          <form
            method="post"
            action="#"
            name="contact"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <div>
              <input type="hidden" name="First Birthday Messages" />
            </div>
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" autoFocus="autofocus" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="email" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Your Message For Addy</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
          </form>

          {close}
        </article>
      </div>
    );
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired
};

export default Main;
